<script setup lang="ts">

</script>

<template>
  <div class="payment-screen-container">
    <slot/>
  </div>
</template>

<style>
.payment-screen-container {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-width: 500px;
  width: 100%;

  box-sizing: border-box;
  background: white;
  padding: clamp(2rem, 4vw, 3rem);
  border-radius: 44px;
  border: 1px solid #E2E8F0;
  margin: 32px auto;
}
</style>